import marked from "marked"

const transformMDtoHTML = (text) => {
  const splitedText = text.split("\n")
  const paragraphs = splitedText
    .map((p) => `<p>${marked.parseInline(p)}</p>`)
    .join("")

  return paragraphs
}

export default transformMDtoHTML
