import React from "react"
import styled, { breakpoints, css, th } from "@xstyled/styled-components"

const textStyle = breakpoints({
  _: css`
    font-weight: ${th.fontWeight("normal")};
    font-size: ${th.fontSize("pl.xl")};
    font-family: ${th.font("1")};
    color: ${th.color("black")};
    line-height: 1.4;
  `,
  xl: css`
    line-height: 1.5;
  `,
})

const HTMLContent = styled.divBox`
  width: 100%;

  em {
    font-style: italic;
  }

  p {
    margin-bottom: 30;
    font-family: ${th.font("1")};
    ${textStyle}
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    list-style-type: disc;
    padding-left: 30px;
    & li {
      font-family: ${th.font("1")};
      margin-bottom: 30;
      ${textStyle}
    }
  }

  strong {
    font-weight: ${th.fontWeight("medium")};
  }

  a {
    word-break: break-word;
  }

  /* ${breakpoints({
    lg: css`
      max-width: 576;
    `,
    xl: css`
      max-width: 555;
    `,
  })} */

  h1 {
    font-weight: ${th("fontWeights.black")};
    line-height: 1.4;
    margin-bottom: 10;
    ${breakpoints({
      _: css`
        font-size: 24;
      `,
      md: css`
        font-size: 60;
      `,
    })}
  }
  h2,
  h3 {
    ${textStyle}
    font-weight: ${th("fontWeights.bold")};
    margin-bottom: 10;
    ${breakpoints({
      _: css`
        font-size: 20;
      `,
      md: css`
        font-size: 32;
      `,
    })}
  }
  h3,
  h4 {
    line-height: 1.5;
    font-weight: ${th("fontWeights.bold")};
    margin-bottom: 10;
    ${breakpoints({
      _: css`
        font-size: 18;
      `,
      md: css`
        font-size: 22;
      `,
    })}
  }
`

export default HTMLContent
